import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import RootPage from '../components/Blog/RootPage'

export default ({ pageContext }) => {
  const { data } = pageContext

  return (
    <Layout data={data}>
      <SEO {...data.seo} />
      <RootPage {...pageContext} />
    </Layout>
  )
}
