import React from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import PostPreview from '../PostPreview'
import Form from '../Form'
import styles from './style.module.sass'

export default ({ data, articles, guides, podcasts }) => {
  const { tags, blogSettings, pageSettings } = data

  return (
    <div className={styles.blog}>
      <div className={styles.wrap}>
        <div className={styles.anchors}>
          <ul className={styles.anchorsList}>
            {tags && tags.map(tag => (
              <li key={tag._key} className={styles.anchorsLink}>
                <Link to={`${pageSettings.path}${blogSettings.tagsLink}/${tag.slug?.current}`}>{tag.title}</Link>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.form}>
          <Form />
        </div>

        {articles && articles.length > 0 && (
          <div className={styles.section}>
            <div id="articles" className={styles.sectionId} />
            <p className={styles.sectionTitle}>Articles</p>
            <div className={styles.sectionTop}>
              <h2 className={styles.sectionH2}>{blogSettings.articlesTitle}</h2>
              <Link className={styles.sectionLink} to={`${pageSettings.path}${blogSettings.articlesLink}`}>
                See All Articles
              </Link>
            </div>
            <div className={cx(styles.sectionContent, styles.sectionContentArticles)}>
              {articles &&
                articles
                  .slice(0, 6)
                  .map(article => (
                    <PostPreview key={article._id} {...article} pageSettings={pageSettings} blogSettings={blogSettings} />
                  ))}
            </div>
          </div>
        )}

        {podcasts && podcasts.length > 0 && (
          <div className={styles.section}>
            <div id="podcasts" className={styles.sectionId} />
            <p className={styles.sectionTitle}>Podcasts</p>
            <div className={styles.sectionTop}>
              <h2 className={styles.sectionH2}>{blogSettings.podcastsTitle}</h2>
              <Link className={styles.sectionLink} to={`${pageSettings.path}${blogSettings.podcastsLink}`}>
                See All Podcasts
              </Link>
            </div>
            <div className={cx(styles.sectionContent, styles.sectionContentPodcasts)}>
              {podcasts &&
                podcasts
                  .slice(0, 2)
                  .map(podcast => (
                    <PostPreview key={podcast._id} {...podcast} pageSettings={pageSettings} blogSettings={blogSettings} />
                  ))}
            </div>
          </div>
        )}

        {guides && guides.length > 0 && (
          <div className={styles.section}>
            <div id="guides" className={styles.sectionId} />
            <p className={styles.sectionTitle}>Guides</p>
            <div className={styles.sectionTop}>
              <h2 className={styles.sectionH2}>{blogSettings.guidesTitle}</h2>
              <Link className={styles.sectionLink} to={`${pageSettings.path}${blogSettings.guidesLink}`}>
                See All Guides
              </Link>
            </div>
            <div className={cx(styles.sectionContent, styles.sectionContentGuides)}>
              {guides &&
                guides
                  .slice(0, 3)
                  .map(guide => (
                    <PostPreview key={guide._id} {...guide} pageSettings={pageSettings} blogSettings={blogSettings} />
                  ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}